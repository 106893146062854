export const TopTokenList = [
  {
    ids: 'ethereum',
    name: 'Ether',
    img: 'https://assets.coingecko.com/coins/images/279/thumb/ethereum.png',
    symbol: 'ETH',
    contractAddress: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    platforms: 'ethereum',
    type: 'coin',
  },
  {
    ids: '',
    name: 'Tether USD',
    img: 'https://assets.coingecko.com/coins/images/325/thumb/Tether.png',
    symbol: 'USDT',
    contractAddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    platforms: 'ethereum',
    type: 'token',
  },
  {
    ids: '',
    name: 'USD Coin',
    img: 'https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png',
    symbol: 'USDC',
    contractAddress: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    platforms: 'ethereum',
    type: 'token',
  },
  {
    ids: 'binancecoin',
    name: 'BNB',
    img: 'https://assets.coingecko.com/coins/images/825/thumb/bnb-icon2_2x.png',
    symbol: 'BNB',
    contractAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    platforms: 'binance-smart-chain',
    type: 'coin',
  },
  {
    ids: '',
    name: 'Matic',
    img: 'https://assets.coingecko.com/coins/images/4713/thumb/matic-token-icon.png',
    symbol: 'MATIC',
    contractAddress: '0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0',
    platforms: 'ethereum',
    type: 'token',
  },
];
