import { Area, AreaChart, ResponsiveContainer } from 'recharts';
import React, { useEffect, useState } from 'react';
import {
  useFlexLayout,
  usePagination,
  useResizeColumns,
  useSortBy,
  useTable,
} from 'react-table';

import ActiveLink from '../ui/links/active-link';
import { ChevronDown } from '@/components/icons/chevron-down';
import Image from 'next/image';
import Scrollbar from '@/components/ui/scrollbar';
import { TopCurrencyData } from '@/data/static/top-currency-data';
import { TopTokenList } from '@/lib/Data/TopToken';
import axios from 'axios';
import cn from 'classnames';
import { routerAbi } from '@/lib/constants/abi';
import { useBreakpoint } from '@/lib/hooks/use-breakpoint';
import { useIsMounted } from '@/lib/hooks/use-is-mounted';
import { useRouter } from 'next/router';
const TokenInfoApi: string | undefined =
  process.env.NEXT_PUBLIC_TOKEN_INFO_API || '';
const numberAbbr = (number: any) => {
  if (number < 1e3) return number;
  if (number >= 1e3 && number < 1e6) return +(number / 1e3).toFixed(1) + 'K';
  if (number >= 1e6 && number < 1e9) return +(number / 1e6).toFixed(1) + 'M';
  if (number >= 1e9 && number < 1e12) return +(number / 1e9).toFixed(1) + 'B';
  if (number >= 1e12) return +(number / 1e12).toFixed(1) + 'T';
};
const COLUMNS = [
  {
    Header: '#',
    accessor: 'id',
    minWidth: 60,
    maxWidth: 80,
  },
  {
    Header: 'Name',
    accessor: 'coin',
    // @ts-ignore
    Cell: ({ cell: { value } }) => (
      // <div className="ltr:text-right rtl:text-left">{value}</div>
      <div className="mb-5 grid grid-cols-3 gap-4 text-sm text-gray-900 last:mb-0 dark:text-white">
        <div className="col-span-2 flex items-center gap-2">
          <span className="w-6 shrink-0">
            <img src={value.img} />
          </span>
          <span className="flex flex-col gap-0.5">
            <span className="whitespace-nowrap text-xs font-medium capitalize">
              {value.name}
            </span>
            <span className="text-xs font-normal text-gray-500 dark:text-gray-400">
              {value.symbol}
            </span>
          </span>
        </div>
      </div>
    ),
    minWidth: 140,
    maxWidth: 260,
  },
  {
    Header: () => (
      <div className="ltr:ml-auto ltr:text-right rtl:mr-auto rtl:text-left">
        Price
      </div>
    ),
    accessor: 'price',
    // @ts-ignore
    Cell: ({ cell: { value } }) => (
      <div className="ltr:text-right rtl:text-left">${value}</div>
    ),
    minWidth: 100,
    maxWidth: 140,
  },
  {
    Header: () => (
      <div className="ltr:ml-auto ltr:text-right rtl:mr-auto rtl:text-left">
        24H Change
      </div>
    ),
    accessor: 'changePrice',
    // @ts-ignore
    Cell: ({ cell: { value } }) => (
      <div
        className={`ltr:text-right rtl:text-left ${
          value > 0 ? 'text-green-500' : 'text-red-500'
        }`}
      >
        {parseFloat(value).toFixed(3)}
      </div>
    ),
    minWidth: 100,
    maxWidth: 140,
  },
  {
    Header: () => (
      <div className="ltr:ml-auto ltr:text-right rtl:mr-auto rtl:text-left">
        24H Volume
      </div>
    ),
    accessor: 'usd_24h_vol',
    // @ts-ignore
    Cell: ({ cell: { value } }) => (
      <div className="ltr:text-right rtl:text-left">
        {numberAbbr(parseInt(value))}
      </div>
    ),
    minWidth: 100,
    maxWidth: 140,
  },
  {
    Header: () => (
      <div className="ltr:ml-auto ltr:text-right rtl:mr-auto rtl:text-left">
        Market Cap
      </div>
    ),
    accessor: 'usd_market_cap',
    // @ts-ignore
    Cell: ({ cell: { value } }) => (
      <div className="flex items-center justify-end">
        {numberAbbr(parseInt(value))}
      </div>
    ),
    minWidth: 100,
    maxWidth: 140,
  },
  // {
  //   Header: () => (
  //     <div className="ltr:ml-auto ltr:text-right rtl:mr-auto rtl:text-left">
  //       7D Chart
  //     </div>
  //   ),
  //   accessor: 'prices',
  //   // @ts-ignore
  //   Cell: ({ cell: { value } }) => (
  //     <div className="h-10 w-full">
  //       <ResponsiveContainer width="100%" height="100%">
  //         <AreaChart data={value}>
  //           <defs>
  //             <linearGradient
  //               id="liquidity-gradient"
  //               x1="0"
  //               y1="0"
  //               x2="0"
  //               y2="1"
  //             >
  //               <stop offset="5%" stopColor="#bc9aff" stopOpacity={0.5} />
  //               <stop offset="100%" stopColor="#7645D9" stopOpacity={0} />
  //             </linearGradient>
  //           </defs>
  //           <Area
  //             type="natural"
  //             dataKey="value"
  //             stroke="#7645D9"
  //             strokeWidth={1.5}
  //             fill="url(#liquidity-gradient)"
  //             dot={true}
  //           />
  //         </AreaChart>
  //       </ResponsiveContainer>
  //     </div>
  //   ),
  //   minWidth: 200,
  //   maxWidth: 300,
  // },
];

export default function TopCurrencyTable({ setTokenInfo }: any) {
  const isMounted = useIsMounted();
  const breakpoint = useBreakpoint();
  const [data, setData] = useState<any[]>([]);
  const [pathName, setPathName] = useState<any>();

  const columns = React.useMemo(() => COLUMNS, []);
  const token = React.useMemo(() => TopTokenList, []);
  const router = useRouter();

  useEffect(() => {
    CryptoToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const CryptoToken = async () => {
    let tokenData = await Promise.all(
      token.map(async (val: any, index: number) => {
        let items: any = await axios.get(
          `${TokenInfoApi}token_prices?platforms=${val.platforms}&contract_address=${val.contractAddress}`
        );
        if (val.type == 'coin') {
          let coins: any = await axios.get(
            `${TokenInfoApi}token_prices?ids=${val.ids}`
          );
          return {
            id: index + 1,
            coin: { name: val.name, symbol: val.symbol, img: val.img },
            price: items.data[val.contractAddress].usd,
            changePrice: items.data[val.contractAddress].usd_24h_change,
            usd_24h_vol: items.data[val.contractAddress].usd_24h_vol,
            usd_market_cap: coins.data[0].market_cap,
            contractAddress: val.contractAddress,
          };
        } else {
          return {
            id: index + 1,
            coin: { name: val.name, symbol: val.symbol, img: val.img },
            price: items.data[val.contractAddress].usd,
            changePrice: items.data[val.contractAddress].usd_24h_change,
            usd_24h_vol: items.data[val.contractAddress].usd_24h_vol,
            usd_market_cap: items.data[val.contractAddress].usd_market_cap,
            contractAddress: val.contractAddress,
          };
        }
      })
    );
    setData(tokenData);
  };

  const {
    getTableProps,
    getTableBodyProps,
    state,
    headerGroups,
    page,
    prepareRow,
  } = useTable(
    {
      // @ts-ignore
      columns,
      data,
    },
    useSortBy,
    useResizeColumns,
    useFlexLayout,
    usePagination
  );

  const { pageIndex } = state;
  let isChildrenActive =
    page &&
    page.some((item, id) => {
      id === pathName;
      // window.scrollTo({
      //   top: 0,
      //   behavior: 'smooth',
      // });
    });

  return (
    <div className="">
      <div className="rounded-tl-lg rounded-tr-lg bg-white px-4 pt-6 dark:bg-light-dark md:px-8 md:pt-8">
        <div className="flex flex-col items-center justify-between border-b border-dashed border-gray-200 pb-5 dark:border-gray-700 md:flex-row">
          <h2 className="mb-3 shrink-0 text-lg font-medium  text-black dark:text-white sm:text-xl md:mb-0 md:text-2xl">
            Token statistics
          </h2>
        </div>
      </div>
      <div className="-mx-0.5">
        <Scrollbar style={{ width: '100%' }}>
          <div className="px-0.5">
            <table
              {...getTableProps()}
              className="transaction-table w-full border-separate border-0"
            >
              <thead className="text-sm text-gray-500 dark:text-gray-300">
                {headerGroups.map((headerGroup, idx) => (
                  <tr {...headerGroup.getHeaderGroupProps()} key={idx}>
                    {headerGroup.headers.map((column, idx) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        key={idx}
                        className="group bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4"
                      >
                        <div className="flex items-center">
                          {column.render('Header')}
                          {column.canResize && (
                            <div
                              {...column.getResizerProps()}
                              className={`resizer ${
                                column.isResizing ? 'isResizing' : ''
                              }`}
                            />
                          )}
                          <span className="ltr:ml-1 rtl:mr-1">
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <ChevronDown />
                              ) : (
                                <ChevronDown className="rotate-180" />
                              )
                            ) : (
                              <ChevronDown className="rotate-180 opacity-0 transition group-hover:opacity-50" />
                            )}
                          </span>
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody
                {...getTableBodyProps()}
                className="b text-xs font-medium text-gray-900 dark:text-white 3xl:text-sm"
              >
                {page.map((row, idx) => {
                  prepareRow(row);
                  return (
                    <ActiveLink
                      href={'#chart'}
                      key={idx}
                      // className="relative mb-3 flex h-12 cursor-pointer items-center  whitespace-nowrap rounded-lg  bg-white px-4 text-sm text-gray-500 shadow-card transition-all last:mb-0  hover:text-brand dark:bg-light-dark dark:hover:text-white"
                      activeClassName="!text-brand dark:!text-white dark:before:!bg-white before:!bg-brand before:!w-2 before:!h-2 before:-ml-0.5 before:ltr:!mr-[18px] before:rtl:!ml-[18px] !font-medium"
                      className={cn(
                        'relative  mb-3 flex  cursor-pointer items-center scroll-smooth  whitespace-nowrap rounded-lg  bg-white px-4 text-sm text-gray-500 shadow-card transition-all   last:mb-0 hover:text-brand focus:text-brand dark:bg-light-dark dark:hover:text-white',
                        isChildrenActive
                          ? 'text-white'
                          : 'text-gray-500 hover:text-brand  dark:hover:text-white'
                      )}
                      // className="mb-3 cursor-pointer items-center rounded-lg bg-white  shadow-card last:mb-0 dark:bg-light-dark"
                    >
                      <tr
                        {...row.getRowProps()}
                        key={idx}
                        onClick={() => {
                          setTokenInfo(TopTokenList[row.values.id - 1]);
                          setPathName(idx);
                        }}
                        // className="mb-3 cursor-pointer items-center rounded-lg bg-white  shadow-card last:mb-0 dark:bg-light-dark"
                      >
                        {row.cells.map((cell, idx) => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              key={idx}
                              className="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5"
                            >
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    </ActiveLink>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Scrollbar>
      </div>
    </div>
  );
}
